import React, { useState, useEffect, useRef } from 'react';
import '../css/lecture.css';
import { getLecturesByCourseId, getLectureById } from './api';  // Correct imports
import { useParams, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Keyboard, Mousewheel } from 'swiper/modules';
import { FaCheckCircle } from 'react-icons/fa'; // Import check mark icon

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/keyboard';
import 'swiper/css/mousewheel';

const LecturePage = () => {
    const { id: courseId } = useParams();  // Get the courseId from the URL
    const [lectures, setLectures] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(null);
    const [watchTime, setWatchTime] = useState(() => {
        const savedProgress = localStorage.getItem('watchTime');
        return savedProgress ? JSON.parse(savedProgress) : {};
    });
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        const fetchLectures = async () => {
            try {
                const response = await getLecturesByCourseId(courseId);  // Fetch lectures by course ID
                setLectures(response.data);
            } catch (error) {
                console.error('Failed to fetch lectures:', error);
            }
        };

        fetchLectures();
    }, [courseId]);

    useEffect(() => {
        if (selectedLecture && videoRef.current && shouldScroll) {
            const progress = watchTime[selectedLecture.id];
            if (progress && videoRef.current.duration) {
                videoRef.current.currentTime = (progress / 100) * videoRef.current.duration;
            }

            if (videoRef.current) {
                videoRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShouldScroll(false);
        }
    }, [selectedLecture, watchTime, shouldScroll]);

    const handleLectureClick = async (id) => {
        try {
            const response = await getLectureById(id);
            setSelectedLecture(response.data);
            setShouldScroll(true);
        } catch (error) {
            console.error('Error fetching lecture details:', error);
        }
    };

    const handleGoToQuizzes = () => {
        if (selectedLecture) {
            navigate(`/quiz/${selectedLecture.id}`);
        }
    };

    const updateWatchTime = (lectureId, progress) => {
        const newWatchTime = {
            ...watchTime,
            [lectureId]: progress,
        };
        setWatchTime(newWatchTime);
        localStorage.setItem('watchTime', JSON.stringify(newWatchTime));
    };

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            let progress = (currentTime / duration) * 100;

            if (Math.abs(progress - (watchTime[selectedLecture.id] || 0)) >= 5) {
                updateWatchTime(selectedLecture.id, progress);
            }

            if (currentTime >= duration - 0.5) {
                updateWatchTime(selectedLecture.id, 100);
            }
        }
    };

    const handleVideoEnded = () => {
        updateWatchTime(selectedLecture.id, 100);
    };

    const calculateProgress = (lecture) => {
        return watchTime[lecture.id] || 0;
    };

    const clearWatchTime = () => {
        localStorage.removeItem('watchTime');
        setWatchTime({});
    };

    return (
        <div className="page-wrapper">
        

            <div className="lecture-page">
                <div className="lecture-content">
                    <div className="slider-and-sidebar">
                        <div className="slider-container">
                            <Swiper
                                modules={[EffectCoverflow, Pagination, Keyboard, Mousewheel]}
                                effect="coverflow"
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={2}
                                spaceBetween={50}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 200,
                                    modifier: 1.2,
                                    slideShadows: true,
                                }}
                                keyboard={{ enabled: true }}
                                mousewheel={{ thresholdDelta: 70 }}
                                loop={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                style={{ height: '600px', width: '100%' }}
            
                            >
                                {lectures.map((lecture) => (
                                    <SwiperSlide key={lecture.id}>
                                        <div
                                            className="lecture-card"
                                            onClick={() => handleLectureClick(lecture.id)}
                                        >
                                            <h2>{lecture.title}</h2>
                                            <p>{lecture.description}</p>
                                            <div className="progress-bar">
                                                <div
                                                    className="progress"
                                                    style={{ width: `${calculateProgress(lecture)}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                           
                            <div className="swiper-pagination"></div>
                      
                        </div>
                        <div className="lecture-sidebar">
                        <h2>Delkurser</h2>
                        <ul className="lecture-list">
                            {lectures.map((lecture) => (
                                <li key={lecture.id} className="lecture-list-item">
                                    <span>{lecture.title}</span>
                                    {calculateProgress(lecture) === 100 && (
                                        <FaCheckCircle className="check-icon" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    </div>
                    
                </div>
               
                {selectedLecture && (
                    <div className="lecture-details">
                        <h2>{selectedLecture.title}</h2>
                        <p>{selectedLecture.description}</p>
                        {selectedLecture.videoUrl ? (
                            <div className="lecture-video">
                                <video
                                    controls
                                    ref={videoRef}
                                    width="100%"
                                    height="400"
                                    onTimeUpdate={handleTimeUpdate}
                                    onEnded={handleVideoEnded}
                                >
                                    <source src={selectedLecture.videoUrl} type="video/mp4" />
                                    <source src={selectedLecture.videoUrl} type="video/webm" />
                                    <source src={selectedLecture.videoUrl} type="video/ogg" />
                                    <source src={selectedLecture.videoUrl} type="video/quicktime" />
                                    Your browser does not support the video element.
                                </video>
                            </div>
                        ) : (
                            <p>Ingen video tillgänglig för denna föreläsning</p>
                        )}
                        <button className="quiz-button" onClick={handleGoToQuizzes}>
                            Gå till frågor
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LecturePage;