import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { getCartItems, removeCartItem } from './api'; // Importera API-funktionen
import '../css/shoppingcart.css';
import { FaTrash } from 'react-icons/fa';

const ShoppingCart = ({ userId, token }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  // Kontrollera om userId och token finns, annars hämta från localStorage
  useEffect(() => {
    const fetchCartItems = async () => {
      const storedUserId = localStorage.getItem('userId'); // Hämta userId från localStorage
      const storedToken = localStorage.getItem('token'); // Hämta token från localStorage

      const user = userId || storedUserId; // Använd prop eller lagrat userId
      const authToken = token || storedToken; // Använd prop eller lagrat token

      if (!user || !authToken) {
        console.error('User is not authenticated');
        setLoading(false);
        return;
      }

      try {
        const data = await getCartItems(user); // Anropa API-funktionen med userId
        setCartItems(data);

        // Beräkna summan av alla varor i kundkorgen
        const total = data.reduce((sum, item) => sum + item.coursePrice * item.quantity, 0);
        setTotalAmount(total);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [userId, token]);

  const handleRemoveItem = async (cartItemId) => {
    try {
      await removeCartItem(cartItemId); // Anropa API för att ta bort varan
      setCartItems(cartItems.filter(item => item.id !== cartItemId)); // Uppdatera UI efter borttagning
      const newTotal = cartItems.reduce((sum, item) => sum + item.coursePrice * item.quantity, 0);
      setTotalAmount(newTotal); // Uppdatera totalbeloppet
    } catch (error) {
      console.error('Error removing cart item:', error);
    }
  };

  const handleCheckout = () => {
    navigate('/checkout'); 
  };

  /*if (loading) {
    return <p>Laddar kundkorgen...</p>;
  }

  if (cartItems.length === 0) {
    return <p className="empty-cart">Din kundkorg är tom</p>;
  }*/

  return (
    <div className="shoppingcart-page">
      <h1>Kundkorg</h1>
      
      {/* Visar laddningsmeddelandet om laddningen pågår */}
      {loading ? (
        <p>Laddar kundkorgen...</p>
      ) : cartItems.length === 0 ? (
        /* Visar meddelandet om kundkorgen är tom */
        <p className="empty-cart">Din kundkorg är tom</p>
      ) : (
        <>

      <table className="cart-table">
        <thead>
          <tr>
            <th>Kurs</th>
            <th>Antal</th>
            <th>Pris</th>
            <th>Totalpris</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr key={item.id}>
              <td>{item.courseTitle}</td>
              <td>{item.quantity}</td>
              <td>{item.coursePrice} SEK</td>
              <td>{item.coursePrice * item.quantity} SEK</td>
              <td>
                <FaTrash
                  className="delete-icon"
                  onClick={() => handleRemoveItem(item.id)} // Ta bort enskild vara
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="cart-total"> 
        <h2>Totalbelopp: {totalAmount} SEK</h2>
      </div>
      
      <button onClick={handleCheckout} className="checkout-button">
        Gå till kassan
      </button>
      </>
       )}
    </div>
  );
};

export default ShoppingCart;
