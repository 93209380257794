import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../css/contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        from_name: '',   
        from_email: '',  // Updated to from_email
        subject: '',     
        message: ''      
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState('');  // 'success' or 'error'
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        if (statusMessage) {
            const fadeOutTimer = setTimeout(() => {
                setIsFadingOut(true);
            }, 2500);  // Start fading out after 2.5 seconds

            const clearTimer = setTimeout(() => {
                setStatusMessage('');
                setStatusType('');
                setIsFadingOut(false);
            }, 3000);  // Remove message completely after 3 seconds

            return () => {
                clearTimeout(fadeOutTimer);
                clearTimeout(clearTimer);
            };
        }
    }, [statusMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_jllcyt6', 'template_c1onpln', formData, 'SRVojJa7xjRGoiK5x', { debug: true })
        .then((result) => {
            console.log('Email sent successfully:', result);
            setStatusMessage('Meddelandet skickades.');
            setStatusType('success');
        }, (error) => {
            console.error('Error sending email:', error);
            setStatusMessage('Det gick inte att skicka meddelandet.');
            setStatusType('error');
        });
    };

    return (
        <div className='contact-page'>
            <div className="contact-container">
                <div className="contact-content">
                    <h1>Kontakt</h1>
                    {statusMessage && (
                        <div className={`status-bar ${statusType} ${isFadingOut ? 'hidden' : ''}`}>
                            {statusMessage}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className='contact-form'>
                        <label htmlFor="from_name" className='contact-label'>Namn:</label>
                        <input 
                            type="text" 
                            name="from_name" 
                            placeholder='Namn' 
                            className='name-input contact-input' 
                            onChange={handleChange} 
                            value={formData.from_name} 
                        />
                        
                        <label htmlFor="from_email" className='contact-label'>Email:</label>
                        <input 
                            type="email" 
                            name="from_email"  // Use 'from_email' to match the formData field
                            placeholder='Email' 
                            className='email-input contact-input' 
                            onChange={handleChange} 
                            value={formData.from_email}   // Ensure it's formData.from_email
                        />
                        
                        <label htmlFor="subject" className='contact-label'>Ämne:</label>
                        <input 
                            type="text" 
                            name="subject" 
                            placeholder='Ämne' 
                            className='subject-input contact-input' 
                            onChange={handleChange} 
                            value={formData.subject}  
                        />
                        
                        <label htmlFor="message" className='contact-label'>Meddelande:</label>
                        <textarea 
                            name="message" 
                            id="message" 
                            placeholder='Meddelande' 
                            className='message-input contact-field' 
                            onChange={handleChange} 
                            value={formData.message}
                        ></textarea>
                        
                        <button type='submit' className='contact-submit-btn'>Skicka</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
