import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logga from '../img/logga2.png';
import '../css/startsida.css';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import '../css/navbar.css';

const Navbar = ({ isLoggedIn, setIsLoggedIn, userRole }) => {
    const [dropdown, setDropdown] = useState(false);
    const [adminDropdown, setAdminDropdown] = useState(false); // Admin dropdown state
    const [navOpen, setNavOpen] = useState(false); // State for mobile menu
    const dropdownRef = useRef(null);
    const personIconRef = useRef(null);
    const adminRef = useRef(null);

    const location = useLocation(); // För att få den aktuella sökvägen

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const toggleAdminDropdown = () => {
        setAdminDropdown(!adminDropdown);
    };

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    const handleMouseEnter = () => {
        setDropdown(true);
    };

    const handleMouseLeave = () => {
        if (dropdownRef.current && personIconRef.current) {
            setDropdown(false);
        }
    };

    const handleAdminMouseEnter = () => {
        setAdminDropdown(true);
    };

    const handleAdminMouseLeave = () => {
        setAdminDropdown(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('userRole'); 
        setIsLoggedIn(false);
        setDropdown(false);
        setAdminDropdown(false);
    }

    const closeNavMenu = () => {
        setNavOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                personIconRef.current &&
                !personIconRef.current.contains(event.target)
            ) {
                setDropdown(false);
            }
            if (
                adminRef.current &&
                !adminRef.current.contains(event.target)
            ) {
                setAdminDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, personIconRef, adminRef]);

    return (
        <div className="startsida-container">
            <div className="rectangle-349"></div>

            {/* Ikoner och navigation */}
            <div className="navbar">
                <Link to="/">
                <img src={Logga} alt="Logga" className="logga" />
                </Link>
                {/* Hamburger menu */}
                <div className="hamburger-menu" onClick={toggleNav}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>

                {/* Navigationslänkar */}
                <div className={`navbar-links ${navOpen ? 'active' : ''}`}>
                    <Link 
                        to="/" 
                        className={`home-link link ${location.pathname === '/' ? 'active' : ''}`}
                        onClick={closeNavMenu}
                    >
                        Hem
                    </Link>
                    <Link 
                        to="/course" 
                        className={`course-link link ${location.pathname === '/course' ? 'active' : ''}`}
                        onClick={closeNavMenu}
                    >
                        Kurser
                    </Link>
                    <Link 
                        to="/about" 
                        className={`about-link link ${location.pathname === '/about' ? 'active' : ''}`}
                        onClick={closeNavMenu}
                    >
                        Om mig
                    </Link>
                    <Link 
                        to="/contact" 
                        className={`contact-link link ${location.pathname === '/contact' ? 'active' : ''}`}
                        onClick={closeNavMenu}
                    >
                        Kontakt
                    </Link>

                    {isLoggedIn && (
                        <>
                           <Link 
                                to="/mycourse" 
                                className={`lecture-link link ${location.pathname === '/mycourse' ? 'active' : ''}`}
                                onClick={closeNavMenu}
                            >
                                Mina kurser
                            </Link>

                            {userRole === 'Admin' && (
                            <div 
                                className="admin-link"
                                onClick={toggleAdminDropdown}
                                onMouseEnter={handleAdminMouseEnter}
                                onMouseLeave={handleAdminMouseLeave}
                                ref={adminRef}
                            >
                                Admin
                            </div>
                            )}

                            {userRole === 'Admin' && adminDropdown && (
                                <div 
                                    className="admin-dropdown"
                                    onMouseEnter={handleAdminMouseEnter}
                                    onMouseLeave={handleAdminMouseLeave}
                                    ref={adminRef}
                                >
                                    <Link 
                                        to="/adminLecture" 
                                        onClick={() => setAdminDropdown(false)}
                                    >
                                        Föreläsningar
                                    </Link>
                                    <Link 
                                        to="/quizAdmin" 
                                        onClick={() => setAdminDropdown(false)}
                                    >
                                        Frågor
                                    </Link>
                                    <Link 
                                        to="/adminCourse" 
                                        onClick={() => setAdminDropdown(false)}
                                    >
                                        Kurser
                                    </Link>
                                    <Link 
                                        to="/adminInvoice" 
                                        onClick={() => setAdminDropdown(false)}
                                    >
                                        Fakturor
                                    </Link>

                                    <Link 
                                        to="/adminUserManagement" 
                                        onClick={() => setAdminDropdown(false)}
                                        >
                                        Användarhantering
                                    </Link>

                                </div>
                            )}
                        
                        </>
                    )}

                    <div className="navbar-icon">
                        {isLoggedIn && (
                            <Link to="/shoppingcart" className="cart-link">
                                <ShoppingCartIcon className="cart-icon" />
                            </Link>
                        )}
                        <div className="person-icon-container"
                            onClick={toggleDropdown}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            ref={personIconRef}
                        >
                            <PersonIcon className="person-icon" />
                        </div>
                    </div>
                </div>

                {dropdown && (
                    <div className="dropdown-menu"
                        ref={dropdownRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {isLoggedIn ? (
                            <>
                                <Link to="/minprofil" onClick={() => { closeNavMenu(); setDropdown(false); }}>Min Profil</Link>
                                <Link to="/order" onClick={() => { closeNavMenu(); setDropdown(false); }}>Order</Link>
                                <Link to="/" onClick={() => { closeNavMenu(); handleLogout(); }}>Logga ut</Link>
                            </>
                        ) : (
                            <>
                                <Link to="/register" onClick={() => { closeNavMenu(); setDropdown(false); }}>Registrera</Link>
                                <Link to="/login" onClick={() => { closeNavMenu(); setDropdown(false); }}>Logga in</Link>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
