import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import Logga from '../img/logga.png';
import '../css/startsida.css';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Image from '../img/2.jpg';
import NewImage from '../img/1.jpg';
import Popup from "../layout/popup";
import Blush from '../img/3.png';

const Startsida = () => {
    const [dropdown, setDropdown] = useState(false);
    const [textVisible, setTextVisible] = useState(false);
    const [imageVisible, setImageVisible] = useState(false);
    const [newTextVisible, setNewTextVisible] = useState(false);
    const [newImageVisible, setNewImageVisible] = useState(false);
    const [circlesVisible, setCirclesVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [ctaVisible, setCtaVisible] = useState(false);
    const [newSectionVisible, setNewSectionVisible] = useState(false);

    const contentRef = useRef(null);
    const newContentRef = useRef(null);
    const circleRef = useRef(null);
    const ctaRef = useRef(null);
    const newSectionRef = useRef(null);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    useEffect(() => {
        // Trigger animation after a short delay
        setTimeout(() => {
            setTextVisible(true);
            setImageVisible(true);
        }, 100);

        const observer = new IntersectionObserver(
            ([entry]) => {
                if(!entry.isIntersecting) {
                    setTextVisible(false);
                    setImageVisible(false);
                } else {
                    setTextVisible(true);
                    setImageVisible(true);
                }
            }, 
            { threshold: 0.1 }
        );

        if(contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if(contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        // Trigger animation for the new section
        setTimeout(() => {
            setNewTextVisible(true);
            setNewImageVisible(true);
        }, 100);

        const observer = new IntersectionObserver(
            ([entry]) => {
                if(!entry.isIntersecting) {
                    setNewTextVisible(false);
                    setNewImageVisible(false);
                } else {
                    setNewTextVisible(true);
                    setNewImageVisible(true);
                }
            }, 
            { threshold: 0.1 }
        );

        if(newContentRef.current) {
            observer.observe(newContentRef.current);
        }

        return () => {
            if(newContentRef.current) {
                observer.unobserve(newContentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setCirclesVisible(true);
        }, 100);

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    setCirclesVisible(false);
                } else {
                    setCirclesVisible(true);
                }
            }, 
            { threshold: 0.1 }
        );

        if (circleRef.current) {
            observer.observe(circleRef.current);
        }

        return () => {
            if (circleRef.current) {
                observer.unobserve(circleRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setCtaVisible(true); // Visa CTA-sektionen
        }, 100);

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    setCtaVisible(false);
                } else {
                    setCtaVisible(true);
                }
            }, 
            { threshold: 0.1 }
        );

        if (ctaRef.current) {
            observer.observe(ctaRef.current);
        }

        return () => {
            if (ctaRef.current) {
                observer.unobserve(ctaRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setNewSectionVisible(true);
        }, 100);

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    setNewSectionVisible(false);
                } else {
                    setNewSectionVisible(true);
                }
            }, 
            { threshold: 0.1 }
        );

        if (newSectionRef.current) {
            observer.observe(newSectionRef.current);
        }

        return () => {
            if (newSectionRef.current) {
                observer.unobserve(newSectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const popupShown = localStorage.getItem('popupShown');

        if(!popupShown) {
            
        // Visa popup efter 2 minuter (120000 ms)
        const timer = setTimeout(() => {
            setShowPopup(true);
            /*localStorage.setItem('popupShown', 'true');*/
        }, 120000);

        return () => clearTimeout(timer); // Rensa timeout vid avmontering
    }
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };
    
    return (
        
        <div className="startsida-page">
            
            <div className="rubrik">
                <h1>Välkommen till Glad Pedagog!</h1>
              </div>  
            <div className="startsida-container">
                <div className="content-containerr" ref={contentRef}>
                    <div className={`text-content ${textVisible ? 'visible' : ''}`}>
                        <h1>Tips och trix för lärare</h1>
                        <h2>Din guide till ett framgångsrikt lärarliv. Allt som lärarhögskolan glömde berätta</h2>
                        <p>
                        Att vara ny lärare kan vara både spännande och utmanande. Hos oss får du verktygen och stödet du behöver för att navigera genom de tidiga åren av din lärarkarriär med självförtroende och framgång. Våra onlinekurser är särskilt utformade för att hjälpa dig hantera de vanligaste utmaningarna som nya lärare möter.
                        
                        </p>
                    </div>
                    <div className={`image-content ${imageVisible ? 'visible' : ''}`}>
                        <img src={Image} alt="Teacher" />
                    </div>
                </div>

                <div className="new-content-container" ref={newContentRef}>
                    <div className={`new-text-content ${newTextVisible ? 'visible' : ''}`}>
                        <h1>Hur kommer det sig att vissa lärare blir en naturlig auktoritet i klassrummet medan andra kämpar som djur för att få eleverna att lyssna?</h1>
                        <p>
                        I våra kurser tar vi reda på hemligheten för att lyckas som lärare. Du får lära dig allt det där som lärarhögskolan ofta glömmer att berätta om.

                        </p>
                    </div>
                    <div className={`new-image-content ${newImageVisible ? 'visible' : ''}`}>
                        <img src={NewImage} alt="Stress Relief" />
                    </div>
                </div>

               
                <div className="courses-sektion">
    <h2 className={`section-title ${circlesVisible ? 'fade-in' : ''}`}>Våra kurser fokuserar på</h2>
    <div className="circles-container" ref={circleRef}>
        <div
            className={`background-imagep ${circlesVisible ? 'fall-in' : ''}`}
            style={{ backgroundImage: `url(${Blush})` }}
        >
            <h3>Stresshantering för lärare</h3>
            <p>
                Stress är en naturlig del av läraryrket, men det behöver inte ta överhanden. I vår kurs lär du dig effektiva strategier för att minska stress, hantera arbetsbelastning och hitta en balans mellan arbete och fritid.
            </p>
        </div>
        <div
            className={`background-imagep ${circlesVisible ? 'fall-in' : ''}`}
            style={{ backgroundImage: `url(${Blush})` }}
        >
            <h3>Skapa studiero i klassrummet</h3>
            <p>
                Ett lugnt och organiserat klassrum är nyckeln till effektivt lärande. Vår kurs ger dig praktiska tips och metoder för att skapa en miljö där eleverna kan fokusera och lära sig utan störningar.
            </p>
        </div>
        <div
            className={`background-imagep ${circlesVisible ? 'fall-in' : ''}`}
            style={{ backgroundImage: `url(${Blush})` }}
        >
            <h3>Naturlig auktoritet i klassrummet</h3>
            <p>
                Att bli en naturlig auktoritet handlar inte om att vara strikt eller hård – det handlar om att vinna elevernas respekt och förtroende. I denna kurs får du lära dig hur du kan bygga en stark relation med dina elever.
            </p>
        </div>
    </div>
</div>

                
                {/* CTA SEKTION */}
                <div className={`cta-section ${ctaVisible ? 'fade-in' : ''}`} ref={ctaRef}>
                    <h2>Börja din resa med oss idag!</h2>
                    <p>
                        Våra kurser är tillgängliga online och kan genomföras i din egen takt. Oavsett om du är ny i yrket eller bara vill förnya dina kunskaper, är våra kurser utformade för att passa dig.    
                        för att registrera dig och börja din resa mot en tryggare och mer framgångsrik lärarkarriär.
                        <br />
                        <Link to="/register" className="cta-link">Klicka här</Link>  <br />  
                    </p>
                    
                </div>

                {/* Ny Sektion */}
                <div className={`new-section ${newSectionVisible ? 'fade-in' : ''}`} ref={newSectionRef}>
                <div className="section-title">
                    <p>Stärk era nyanställda lärare med rätt stöd från början</p>
                
                    </div>
                <div className="intro-text">
                    <h2>För en hållbar och framgångsrik lärarkår</h2>
                    <p>Som skolhuvudmän är ni medvetna om vikten av att ge era nyanställda lärare en bra start. De första åren i yrket är avgörande för att bygga upp den kompetens och trygghet som behövs för att trivas och lyckas i sin roll. Men trots en god introduktion och stöttning från kollegor kan många nya lärare uppleva stor stress och osäkerhet i sin vardag. Enligt statistik från Statistiska centralbyrån (SCB) går många lärare in i väggen redan inom de första två åren av sin karriär. Den här kritiska perioden innebär att vi behöver erbjuda mer än bara grundläggande introduktion och stöd.</p>
                </div>
                
                <div className="second-section">
                    <h2>För att förebygga detta och skapa en stark, hållbar lärarkår behöver vi erbjuda mer än bara den grundläggande introduktionen.</h2>
                    <p>Våra onlinekurser är utformade för att komplettera den introduktion som redan ges på arbetsplatsen. De ger nyanställda lärare konkreta verktyg och strategier för att hantera vanliga utmaningar i klassrummet och i arbetsmiljön:</p>
                </div>
                
                <ul className="bulleted-list">
                    <li><strong>Stresshantering:</strong> Våra kurser hjälper lärare att utveckla effektiva strategier för att hantera stress och arbetsbelastning. Genom att lära sig att känna igen och hantera stressymptom i ett tidigt skede kan lärare undvika utmattning och bibehålla sitt välmående.</li>
                    <li><strong>Skapa studiero:</strong> Ett lugnt och organiserat klassrum är nyckeln till effektivt lärande. Vi erbjuder praktiska tekniker för att skapa en miljö där eleverna kan fokusera och lära, vilket i sin tur leder till en mer produktiv och harmonisk arbetsmiljö för läraren.</li>
                    <li><strong>Naturlig auktoritet:</strong> Många nya lärare kämpar med att etablera sig som auktoriteter i klassrummet. Vår kurs ger dem de färdigheter de behöver för att leda med självsäkerhet och vinna elevernas respekt, utan att behöva vara auktoritära.</li>
                </ul>
                
                <div className="flexibility-text">
                    <p><strong>Kurserna är flexibla och tillgängliga online, vilket gör dem lätta att integrera i den befintliga introduktionen.</strong> Genom att erbjuda dessa kurser till era nyanställda lärare kan ni ge dem den extra stöttning de behöver för att snabbt hitta sin roll, utveckla sitt ledarskap och skapa en trygg och positiv klassrumsmiljö.</p>
                </div>
                
                <div className="investment-text">
                    <p><strong>Investera i era lärare – och i er skolas framtid.</strong> Genom att ge nyanställda lärare rätt stöd från början skapar ni förutsättningar för en långsiktig och hållbar utveckling i läraryrket. Tillsammans kan vi bygga en starkare skola där både elever och lärare trivs och utvecklas.</p>
                </div>
                
                <div className="contact-text">
                    <p>
                    <Link to="/contact" className="contact-link">Kontakta oss</Link> idag för att diskutera hur våra kurser kan bli en värdefull del av er introduktion för nyanställda lärare.
                        
                        </p>
                </div>
            </div>
            </div>
            
            <Popup isOpen={showPopup} onClose={closePopup} />
        </div>
       
    );
};

export default Startsida;
