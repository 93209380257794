import React from 'react';
import '../css/about.css';
import Karin from '../img/Om mig.jpg';
import Karin2 from '../img/Karin.jpg';

const About = () => {

    return (
        <div className='about-container'>
            <header className='about-title'>
                <h1 className='title'>Om mig</h1>
            </header>
            <section className='about-content-container '>
                <div className='about-content'>
                    <p className='about-paragraph'>
                        Hej! Jag heter Karin Amador.
                        <br /><br />
                        Jag har arbetat som lärare i åtta år, och jag kan ärligt säga att det inte alltid har varit en dans
                        på rosor. Precis som många nya lärare har jag känt ångest inför att gå till jobbet på
                        måndagar. Det var inte ovanligt att jag gick hem och grät de första veckorna som lärare, och
                        jag minns hur jag kunde sitta i bilen på morgonen och sucka över att behöva kliva in i skolan.
                        Det var stunder då jag verkligen funderade på om det var värt det.
                    </p>
                    <p className='about-paragraph'>
                        Och jag kunde inte förstå varför det kändes så här. Jag var ju så snäll, jag ville verkligen
                        mina elevers bästa! Märkte de inte det? Förstod de inte hur lyckligt lottade de var som hade
                        en lärare som brydde sig så mycket som jag gjorde? Jag ville vara den bästa läraren de
                        någonsin haft, men ändå kändes det som om jag misslyckades.
                    </p>
                    <p className='about-paragraph'>
                        Genombrottet kom tack vare mina fantastiska kollegor och genom egna erfarenheter.
                        Jag känner att jag äntligen har knäckt koden för hur man blir en respektingivande och
                        omtyckt lärare som trivs på jobbet. Det handlar inte om att vara snäll hela tiden, utan om att
                        hitta balansen mellan tydlighet, empati och att sätta gränser. Att vara en naturlig auktoritet
                        och samtidigt visa omsorg.
                    </p>
                </div>
                <img src={Karin2} alt='Bild på Karin' className='image2 image' />
                <p className='about-paragraph wide-p'>
                    En kollega sa en gång till mig att det tar fem år innan man verkligen känner sig säker i
                    läraryrket. Fem år innan man känner sig trygg i sin roll och mår bra på jobbet. Samtidigt visar
                    statistik från SCB att många lärare går in i väggen redan inom de första två åren. Ska det
                    verkligen behöva vara så?
                    <br />
                    Jag tror att det finns ett bättre sätt. Därför har jag skapat mina kurser. Genom att erbjuda
                    en bra introduktion redan i början av din lärarkarriär, kan du bygga den trygghet och
                    kompetens som krävs för att trivas och lyckas som lärare – utan att det ska ta fem år och
                    utan att du ska riskera att bränna ut dig.
                    <br />
                    Mina kurser kommer att kräva engagemang från din sida. Du kommer att få göra övningar
                    och reflektera över ditt arbetssätt. Men jag lovar att det kommer vara värt det. Det handlar
                    om att bygga en grund som gör att du kan möta varje dag med självförtroende och glädje,
                    och förhoppningsvis slippa de söndagskvällar fyllda av ångest som jag själv upplevde.
                    Välkommen till en resa mot att bli den lärare du alltid velat vara!
                </p>

                <div className='about-image'>
                    
                    <img src={Karin} alt='Bild på Karin' className='image' />
                </div>
            </section>
        </div>
    );
};

export default About;
