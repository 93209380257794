import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../css/checkout.css';

const stripePromise = loadStripe('pk_test_51PMWzMRwfmoIMejsktwrFfF20B8tXxh5Be0M3qZwGyZwc0L9Md6vUd96HkTrqlDYQD0M1ZWREyUPnb7kHzy2AInk00aaMNq7iB');

const CheckoutForm = ({ cartItems, totalAmount, userId, onPaymentSuccess, billingDetails }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPaymentProcessing(true);
    
        if (!stripe || !elements) {
            return;
        }
    
        const cardElement = elements.getElement(CardElement);
    
        // Create payment method using Stripe with billing details
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                email: billingDetails.email,
                address: {
                    line1: billingDetails.address,
                    city: billingDetails.city,
                    postal_code: billingDetails.postalCode,
                    country: billingDetails.region,
                },
            },
        });
        console.log({
            userId: userId,
            paymentMethodId: paymentMethod.id,
            paymentMethod: 'stripe',
            customerId: null
        });
        if (error) {
            setErrorMessage(error.message);
            setPaymentProcessing(false);
            return;
        }
    
        console.log('PaymentMethod created:', paymentMethod);
    
        try {
            const token = localStorage.getItem('token'); // Get JWT token
        
            // Send only relevant data to the backend
            const response = await fetch('https://gladpedagog-9pc4j.ondigitalocean.app/api/checkout/checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include JWT token
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    paymentMethodId: paymentMethod.id, // Send the Stripe payment method ID
                    paymentMethod: 'stripe',           // Specify the payment method
                    customerId: null,                  // Pass customerId if available, or handle customer creation in the backend
                }),
            });
        
            // Ensure the response is in JSON format
            const result = await response.json();
        
            if (response.ok && result.success) {
                alert(result.message); // Display success message
                onPaymentSuccess(); // Trigger success callback
            } else {
                throw new Error(result.message || 'Payment failed');
            }
        } catch (error) {
            setErrorMessage(error.message || 'Payment failed. Please try again.');
            console.error('Error processing payment:', error);
        }
        
    
        setPaymentProcessing(false);
    };
    
    
    return (
        <form onSubmit={handleSubmit} className="stripe-payment-form fade-up">
            <div className="stripe-card-input">
                <label htmlFor="card-element">Kortinformation</label>
                <CardElement id="card-element" />
            </div>

            {errorMessage && <div className="card-error" role="alert">{errorMessage}</div>}

            <button type="submit" className="submit-btn" disabled={paymentProcessing}>
                {paymentProcessing ? 'Processing...' : 'Submit Payment'}
            </button>
        </form>
    );
};

const Checkout = () => {
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [billingDetails, setBillingDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        city: '',
        postalCode: '',
        region: '',
        phone: ''
    });
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    const token = localStorage.getItem('token'); // Retrieve the JWT token

    useEffect(() => {
        const fetchCartItems = async () => {
            if (!userId || !token) {
                console.error('User is not authenticated');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://gladpedagog-9pc4j.ondigitalocean.app/api/ShoppingCart/items/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch cart items');
                }

                const data = await response.json();
                setCartItems(data);

                // Calculate total price
                const total = data.reduce((sum, item) => sum + item.coursePrice * item.quantity, 0);
                setTotalAmount(total);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching cart items:', error);
                setLoading(false);
            }
        };

        fetchCartItems();
    }, [userId, token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBillingDetails({ ...billingDetails, [name]: value });
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handlePaymentSuccess = () => {
        // Handle success, such as redirecting to a thank you page or clearing the cart
        alert('Order placed successfully!');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="checkout-container fade-up">
            <h1>Kassa</h1>
            <Elements stripe={stripePromise}>
                <section className="checkout-content fade-up">
                    {/* Billing Details Form */}
                    <div className="checkout-form fade-up">
                        <h2>Faktureringsdetaljer</h2>
                        <form>
                            {/* Billing Details */}
                            <div className="form-group fade-up">
                                <label htmlFor="form_firstname">Förnamn<span className="needed">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Förnamn"
                                    name="firstName"
                                    value={billingDetails.firstName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group fade-up">
                                <label htmlFor="form_lastname">Efternamn<span className="needed">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Efternamn"
                                    name="lastName"
                                    value={billingDetails.lastName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group fade-up">
                                <label htmlFor="form_email">E-postadress<span className="needed">*</span></label>
                                <input
                                    type="email"
                                    placeholder="E-postadress"
                                    name="email"
                                    value={billingDetails.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group fade-up">
                                <label htmlFor="form_region">Land/Region<span className="needed">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Land/Region"
                                    name="region"
                                    value={billingDetails.region}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group form-group-full fade-up">
                                <label htmlFor="form_address">Gatuadress<span className="needed">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Gatunamn och husnummer"
                                    name="address"
                                    value={billingDetails.address}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input type="text" placeholder="Lägenhetsnr, våning, osv (valfritt)" name="apartment" />
                            </div>
                            <div className="form-group fade-up">
                                <label htmlFor="form_postalcode">Postnummer<span className="needed">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Postnummer"
                                    name="postalCode"
                                    value={billingDetails.postalCode}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group fade-up">
                                <label htmlFor="form_phone">Telefon (valfritt)</label>
                                <input
                                    type="text"
                                    placeholder="Telefon"
                                    name="phone"
                                    value={billingDetails.phone}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </form>
                    </div>

                    {/* Order Summary and Stripe Payment */}
                    <div className="checkout-cart fade-up">
                        <h2>Din Beställning</h2>
                        <div className="cart-summary fade-up">
                            {cartItems.length > 0 ? (
                                cartItems.map((item, index) => (
                                    <div key={index} className="line-item fade-up">
                                        <span>{item.courseTitle} × {item.quantity}</span>
                                        <span>{(item.coursePrice * item.quantity).toFixed(2)} kr</span>
                                    </div>
                                ))
                            ) : (
                                <div>No items in your cart</div>
                            )}
                            <div className="line-item fade-up">
                                <span>Delsumma</span>
                                <span>{totalAmount.toFixed(2)} kr</span>
                            </div>
                            <div className="line-item total fade-up">
                                <span>Totalt</span>
                                <span>{totalAmount.toFixed(2)} kr</span>
                            </div>
                        </div>

                        {/* Payment Method Selection */}
                        <div className="payment-options fade-up">
                            <label className="label">
                                <input
                                    type="radio"
                                    name="payment_method"
                                    value="stripe"
                                    checked={paymentMethod === 'stripe'}
                                    onChange={handlePaymentMethodChange}
                                />
                                kreditkort (stripe)
                            </label>
                            {paymentMethod === 'stripe' && (
                                <div className="stripe-details fade-up">
                                    <CheckoutForm
                                        cartItems={cartItems}
                                        totalAmount={totalAmount}
                                        userId={userId}
                                        billingDetails={billingDetails} // Pass billing details to the form
                                        onPaymentSuccess={handlePaymentSuccess}
                                    />
                                </div>
                            )}

                            <label className="label">
                                <input
                                    type="radio"
                                    name="payment_method"
                                    value="invoice"
                                    checked={paymentMethod === 'invoice'}
                                    onChange={handlePaymentMethodChange}
                                />
                                Faktura
                            </label>
                        </div>

                        {/* Submit Button */}
                        <button className="submit-btn fade-up">Lägg beställning</button>

                        {/* Back to Cart Link */}
                        <div className="back-to-cart fade-up">
                            <a href="/shoppingcart">« Tillbaka till varukorgen</a>
                        </div>
                    </div>
                </section>
            </Elements>
        </div>
    );
};

export default Checkout;
