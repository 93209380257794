import React, { useState } from 'react';
import '../css/login.css';
import { loginUser } from './api';
import { useNavigate } from 'react-router-dom';


const Login = ({ setIsLoggedIn, setUserRole }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!email || !password) {
            setError('Både e-post och lösenord måste anges.');
            return;
        }
    
        try {
            const response = await loginUser(email, password);
            if (response.data && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('userRole', response.data.userRole); // Spara userId också
                setIsLoggedIn(true);
                setUserRole(response.data.userRole);
                navigate('/startsida');
            } else {
                setError('Inloggningen misslyckades, kontrollera användaruppgifterna!');
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError('Felaktiga inloggningsuppgifter, vänligen försök igen.');
            } else {
                setError('Ett oväntat fel inträffade. Vänligen försök igen senare.');
            }
            console.error("Login error:", err);
        }
    };

    return (

        <div className="login-page">
        
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2>Logga in</h2>
                <input 
                    type="email" 
                    placeholder="Email" 
                    autoComplete="Email"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <input 
                    type="password" 
                    placeholder="Lösenord" 
                    autoComplete="Lösenord"
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    
                />
                <button type="submit">Logga in</button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    </div>
    );
};

export default Login;
  