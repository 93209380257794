import React, { useState, useEffect } from 'react';

const AdminLectureForm = ({ initialLecture, onSubmit }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [courseId, setCourseId] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (initialLecture) {
            setTitle(initialLecture.title || '');
            setDescription(initialLecture.description || '');
            setCourseId(initialLecture.courseId || '');
            

        } else { // rensa formuläret
            setTitle('');
            setDescription('');
            setCourseId('');
            setFile(null);
        }

    }, [initialLecture]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const lectureData = { title, description, courseId, file };
        onSubmit(lectureData, initialLecture?.id);

        if (!initialLecture) {
            setTitle('');
            setDescription('');
            setCourseId('');
            setFile(null);
        }
    };

    // Unique key for file input to force re-render
    const fileInputKey = !initialLecture ? Date.now() : initialLecture.id;
    
    return (
        <form onSubmit={handleSubmit} className="lecture-form">
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titel"
                required
            />
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Beskrivning"
                required
            />
            <input
                type="number"
                value={courseId}
                onChange={(e) => setCourseId(e.target.value)}
                placeholder="Kurs id"
                required
            />
            <input
                type="file"
                onChange={handleFileChange}
            />
            <input
                 type="submit"
                 value={initialLecture ? 'Uppdatera Delkurs' : 'Skapa Delkurs'}
            />
        </form>
    );
};

export default AdminLectureForm;
