import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/order.css';

const Order = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch userId from localStorage
    const userId = localStorage.getItem('userId'); // Retrieve the actual user ID from localStorage

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve JWT token from localStorage

                const response = await fetch(`https://gladpedagog-9pc4j.ondigitalocean.app/api/order/user/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include JWT token in Authorization header
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }

                const data = await response.json();
                setOrders(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false);
            }
        };

        fetchOrders();
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="order-page">
            <h1>Order</h1>
            <table className="order-table">
                <thead>
                    <tr>
                        <th>Ordernummer</th>
                        <th>Order Datum</th>
                        <th>Total belopp</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.length > 0 ? (
                        orders.map((order, index) => (
                            <tr key={index}>
                                <td>{order.orderNumber}</td>
                                <td>{order.orderDate ? new Date(order.orderDate).toLocaleDateString() : 'N/A'}</td>
                                <td>
                                    {order.totalAmount ? `${order.totalAmount} kr` : 'N/A'}
                                    <Link to={`/order/${order.id}`} className="arrow">&gt;</Link>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">No orders found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Order;
