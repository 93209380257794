import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './adminCourse.css'; // En CSS-fil för styling
import { getAllCourses, createCourse, updateCourse, deleteCourse } from '../js/api';




const AdminCourse = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [newCourse, setNewCourse] = useState({
        title: '',
        description: '',
        price: '',
        userId: ''
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await getAllCourses();
                setCourses(response.data);
            } catch (error) {
                console.error('Fel vid hämtning av kurser:', error);
            }
        };

        fetchCourses();
    }, []);

    const handleCreateCourse = async () => {
        if (!videoFile || !imageFile) {
            alert('Vänligen välj både en video och en bildfil.');
            return;
        }
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('Title', newCourse.title);
        formData.append('Description', newCourse.description);
        formData.append('Price', newCourse.price); // Använd samma format som i Postman, t.ex. '20,99'
        formData.append('UserId', newCourse.userId);
        formData.append('ImageUrl', 'http://example.com/images/'); // Fyll i en giltig URL som i Postman
        formData.append('VideoUrl', 'http://your-ftp-server.com/uploads/film.mov'); // Fyll i en giltig URL som i Postman
        formData.append('VideoFile', videoFile); // Se till att detta refererar till korrekt fil i React
        formData.append('ImageFile', imageFile); // Se till att detta refererar till korrekt fil i React
    
        try {
            const response = await createCourse(formData); 
            alert('Kurs skapad framgångsrikt');
            setNewCourse({ title: '', description: '', price: '', userId: '' });
            setVideoFile(null);
            setImageFile(null);
            setCourses([...courses, response.data]);
        } catch (error) {
            if (error.response) {
                // Logga hela felresponsen
                console.error('Fel vid skapande av kurs:', error.response);
                alert(`Misslyckades med att skapa kurs: ${error.response.data.errors ? JSON.stringify(error.response.data.errors) : error.response.data}`);
            } else {
                console.error('Fel vid skapande av kurs:', error.message);
                alert(`Misslyckades med att skapa kurs: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };
    
    const handleUpdateCourse = async (id) => {
        if (selectedCourse) {
            setLoading(true);
    
            const formData = new FormData();
            formData.append('Title', selectedCourse.title);
            formData.append('Description', selectedCourse.description);
            formData.append('Price', selectedCourse.price);
            formData.append('UserId', selectedCourse.userId);
    
            // Om nya filer har valts, lägg till dem i formData. Annars, använd befintliga URLs
            if (videoFile) {
                formData.append('VideoFile', videoFile); // Skickar videofilen om en ny har valts
            } else if (selectedCourse.videoUrl) {
                formData.append('VideoUrl', selectedCourse.videoUrl); // Skickar video-URL om ingen ny fil har valts
            }
    
            if (imageFile) {
                formData.append('ImageFile', imageFile); // Skickar bildfilen om en ny har valts
            } else if (selectedCourse.imageUrl) {
                formData.append('ImageUrl', selectedCourse.imageUrl); // Skickar bild-URL om ingen ny fil har valts
            }
    
            try {
                const response = await updateCourse(id, formData);
                alert('Kurs uppdaterad!');
                setCourses(courses.map(course => course.id === id ? { ...course, ...selectedCourse } : course));
                setSelectedCourse(null); // Återställ valt kursobjekt
            } catch (error) {
                console.error('Fel vid uppdatering av kurs:', error.response ? error.response.data : error.message);
                alert(`Misslyckades med att uppdatera kurs: ${error.response ? error.response.data.errors : error.message}`);
            } finally {
                setLoading(false);
            }
        }
    };
    
    const handleCancelUpdate = () => {
        setSelectedCourse(null);
        navigate('/adminCourse'); // 
    };
    
    const handleDeleteCourse = async (id) => {
        try {
            await deleteCourse(id);
            setCourses(courses.filter(course => course.id !== id));
            alert('Kurs raderad!');
        } catch (error) {
            console.error('Fel vid radering av kurs:', error.response ? error.response.data : error.message);
            alert(`Misslyckades med att radera kurs: ${error.response ? error.response.data.errors : error.message}`);
        }
    };

    const filteredCourses = courses.filter(course => course.title.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div className="course-manager">
            <h1>Kurser</h1>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Sök kurser"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="create-course">
                <h2>Skapa ny kurs</h2>
                <input
                    type="text"
                    placeholder="Titel"
                    value={newCourse.title}
                    onChange={(e) => setNewCourse({ ...newCourse, title: e.target.value })}
                />
                <textarea
                    placeholder="Beskrivning"
                    value={newCourse.description}
                    onChange={(e) => setNewCourse({ ...newCourse, description: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Pris"
                    value={newCourse.price}
                    onChange={(e) => setNewCourse({ ...newCourse, price: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Användar-ID"
                    value={newCourse.userId}
                    onChange={(e) => setNewCourse({ ...newCourse, userId: e.target.value })}
                />
                <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => setVideoFile(e.target.files[0])}
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImageFile(e.target.files[0])}
                />
                <button onClick={handleCreateCourse} disabled={loading}>
                    {loading ? 'Laddar upp...' : 'Skapa kurs'}
                </button>
            </div>

            <h2 className="course-list-title">Kurslista</h2> {/* Rubriken för kurslistan */}
            <div className="course-list">
                {filteredCourses.map(course => (
                    <div key={course.id} className="course-card">
                        <h3>{course.title}</h3>
                        <p>{course.description}</p>
                        <p>{course.price} kr</p>
                        <div className="button-container">
                            <button onClick={() => setSelectedCourse(course)}>Redigera</button>
                            <button onClick={() => handleDeleteCourse(course.id)}>Radera</button>
                        </div>
                    </div>
                ))}
            </div>
            {selectedCourse && (
                <div className="update-course">
                    <h2>Uppdatera kurs</h2>
                    <input
                        type="text"
                        placeholder="Titel"
                        value={selectedCourse.title}
                        onChange={(e) => setSelectedCourse({ ...selectedCourse, title: e.target.value })}
                    />
                    <textarea
                        placeholder="Beskrivning"
                        value={selectedCourse.description}
                        onChange={(e) => setSelectedCourse({ ...selectedCourse, description: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Pris"
                        value={selectedCourse.price}
                        onChange={(e) => setSelectedCourse({ ...selectedCourse, price: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Användar-ID"
                        value={selectedCourse.userId}
                        onChange={(e) => setSelectedCourse({ ...selectedCourse, userId: e.target.value })}
                    />

                    {/* Visa den nuvarande videon om det finns en URL */}
                    {selectedCourse.videoUrl && (
                        <div>
                            <p>Nuvarande video: {selectedCourse.videoUrl}</p>
                        </div>
                    )}
                    <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => setVideoFile(e.target.files[0])}
                    />

                    {/* Visa nuvarande bild om det finns en URL */}
                    {selectedCourse.imageUrl && (
                        <div>
                            <p>Nuvarande bild: {selectedCourse.imageUrl}</p>
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImageFile(e.target.files[0])}
                    />

                    <div className="button-container">
                        <button onClick={() => handleUpdateCourse(selectedCourse.id)} disabled={loading}>
                            {loading ? 'Uppdaterar...' : 'Uppdatera kurs'}
                        </button>

                        <button onClick={handleCancelUpdate} className="cancel-button">
                            Avbryt
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminCourse;