import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/course.css';  // Adjust the path if necessary
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const Course = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [coursesPerPage, setCoursesPerPage] = useState(9);
    const [courses, setCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [fadeInClass, setFadeInClass] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const updateCoursesPerPage = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 570) {
                setCoursesPerPage(4);
            } else if (screenWidth < 900) {
                setCoursesPerPage(6);
            } else {
                setCoursesPerPage(9);
            }
        };

        updateCoursesPerPage();
        window.addEventListener('resize', updateCoursesPerPage);

        return () => {
            window.removeEventListener('resize', updateCoursesPerPage);
        };
    }, []);

    useEffect(() => {
        setFadeInClass('fade-in');

        const fetchCourses = async () => {
            try {
                const response = await fetch('https://gladpedagog-9pc4j.ondigitalocean.app/course/GetAllCourses');
                if (!response.ok) {
                    throw new Error('Failed to fetch courses');
                }
                const data = await response.json();
                setCourses(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    const pageCount = Math.ceil(courses.length / coursesPerPage); // Calculate number of pages based on courses and courses per page

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredCourses = courses // Filter courses based on search query and pagination
        .filter(course => course.title.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice((currentPage - 1) * coursesPerPage, currentPage * coursesPerPage);

        const handleAddToCart = async (course) => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
        
            if (!token || !userId) {
                alert('User not logged in');
                navigate('/login');  // Redirect to login page if not logged in
                return;
            }
        
            const cartItem = {
                userId: parseInt(userId),
                courseId: course.id,
                courseTitle: course.title,
                coursePrice: course.price,
                quantity: 1
            };
        
            try {
                const response = await fetch('https://gladpedagog-9pc4j.ondigitalocean.app/api/ShoppingCart/add', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(cartItem)
                });
        
                if (response.ok) {
                    const data = await response.json();
                    alert(`Course "${course.title}" added to the cart!`);
                } else {
                    throw new Error('Failed to add the item to the cart');
                }
            } catch (error) {
                console.error('Error adding item to the cart:', error);
                alert('An error occurred while adding the course to the cart.');
            }
        };
    const handleReadMore = (course) => {
        navigate(`/course/${course.id}`, { state: course });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`course-container ${fadeInClass}`}>
            <h1 className='course-title'>Kurser</h1>

            {courses.length >= 9 && (
                <div className="search-bar-container">
                    <input
                        type="text"
                        placeholder="Sök"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                    <button className="search-button" type="submit">
                        <ZoomInIcon />
                    </button>
                </div>
            )}

            <div className="courses-grid">
                {filteredCourses.map((course, index) => (
                    <div key={index} className="card">
                        <h2><b>{course.title}</b></h2>
                        <img
    src={course.imageUrl || "https://via.placeholder.com/210"}
    alt="course"
/>
                        <p className='price'>{course.price} kr</p>
                        <div className="button-group">
                            <button className="btn" onClick={() => handleAddToCart(course)}>Köp</button>
                            <button className="btn" onClick={() => handleReadMore(course)}>Läs mer</button>
                        </div>
                    </div>
                ))}
            </div>

            {courses.length >= 9 && (
                <div className="pagination">
                    {Array.from({ length: pageCount }, (_, i) => (
                        <button
                            key={i}
                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                            onClick={() => changePage(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Course;
