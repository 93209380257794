import React, { useState, useEffect } from 'react';
import { getUserById, updateUserProfile } from './api'; // Se till att importera korrekta metoder
import '../css/minprofil.css';


const MinProfil = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userId = localStorage.getItem('userId');
                console.log("User ID:", userId); // Lägg till denna rad för att logga User ID
                if (!userId) {
                    setError('User ID is not available.');
                    return;
                }

                const response = await getUserById(userId);
                setFirstName(response.firstName || '');
                setLastName(response.lastName || '');
                setStreet(response.street || '');
                setCity(response.city || '');
                setPostalCode(response.postalCode || '');
                setCountry(response.country || '');
            } catch (err) {
                console.error("Failed to fetch user profile:", err);
                setError('Failed to fetch profile');
            }
        };

        fetchUserProfile();
    }, []);

    const validatePassword = (password) => {
        if (password.length < 6) {
            return 'Lösenordet måste vara minst 6 tecken långt';
        }
        if (!/[A-Z]/.test(password)) {
            return 'Lösenordet måste innehålla minst en stor bokstav';
        }
        if (!/[0-9]/.test(password)) {
            return 'Lösenordet måste innehålla minst en siffra';
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            return 'Lösenordet måste innehålla minst ett specialtecken';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password) {
            const passwordError = validatePassword(password);
            if (passwordError) {
                setError(passwordError);
                return;
            }
        }
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('User ID is not available.');
                return;
            }

            await updateUserProfile(userId, firstName, lastName, street, city, postalCode, country, password);
            setSuccess('Profile updated successfully');
            setError('');
        } catch (err) {
            console.error("Update failed:", err);
            setError('Failed to update profile');
            setSuccess('');
        }
    };

    // Effekt för att rensa framgångs- och felmeddelanden efter 5 sekunder
    useEffect(() => {
        if (error || success) {
            const timer = setTimeout(() => {
                setError('');
                setSuccess('');
            }, 5000); // Meddelanden försvinner efter 5 sekunder

            return () => clearTimeout(timer); // Rensar timer om komponenten unmountas eller meddelanden uppdateras
        }
    }, [error, success]);

    return (
        <div className="wrapper">
        <div className="min-profil">
            <h2>Min Profil</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                />
                <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                />
                <input
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    placeholder="Street"
                />
                <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                />
                <input
                    type="text"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    placeholder="Postal Code"
                />
                <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Country"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New Password (optional)"
                />
                <button type="submit">Updatera Profil</button>
            </form>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
        </div>
        </div>
    );
};

export default MinProfil;
