import React from 'react';
import Footer from '../layout/footer';
import Navbar from '../layout/navbar';

const Layout = ({ children, isLoggedIn, setIsLoggedIn, userRole, backgroundColor }) => {
    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh', 
            backgroundColor: backgroundColor || '#FFFFFF'  // Use the passed backgroundColor prop or default to white
        }}>
            <Navbar 
                isLoggedIn={isLoggedIn} 
                setIsLoggedIn={setIsLoggedIn}
                userRole={userRole} // Skicka med userRole till Navbar
            />
            <div style={{ 
                flex: 1, 
                backgroundColor: backgroundColor || '#FFFFFF'  // Ensure the content area has the same background
            }}>
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
