import React, { useState, useEffect } from 'react';
import { getAllQuizzes, validateAnswer } from './api';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/quiz.css';

function Quiz() {
    const { lectureId } = useParams(); // Hämta lectureId från URL om det finns
    const [quizzes, setQuizzes] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [validationResult, setValidationResult] = useState(null);
    const [message, setMessage] = useState('');
    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [score, setScore] = useState(0); // Poängräkning
    const [completedQuestions, setCompletedQuestions] = useState(new Set());
    const [userAnswers, setUserAnswers] = useState([]); // håller reda på användarens svar och restultat
    const navigate = useNavigate();


    
    useEffect(() => {
    const loadQuizzes = async () => {
        try {
            const response = await getAllQuizzes();
            const filteredQuizzes = lectureId 
                ? response.data.filter(quiz => quiz.lectureId === parseInt(lectureId))
                : response.data;
            setQuizzes(filteredQuizzes);

            if (filteredQuizzes.length === 0) {
                setMessage('Inga frågor tillgängliga för denna föreläsning.');
            } else {
                setMessage('');
            }
        } catch (error) {
            console.error('Det gick inte att hämta frågorna:', error);
        }
    };

     loadQuizzes();
    }, [lectureId]);

    const handleSubmitAnswer = async () => {
        if (completedQuestions.has(currentQuizIndex)) {
            setMessage('Du har redan svarat på denna fråga.');
            return;
        }

        try {
            const response = await validateAnswer(quizzes[currentQuizIndex].id, selectedAnswer);
            setValidationResult(response);


            setCompletedQuestions(new Set([...completedQuestions, currentQuizIndex]));
            setUserAnswers([...userAnswers, {
                question: quizzes[currentQuizIndex].question,
                selectedAnswer: selectedAnswer,
                correctAnswer: quizzes[currentQuizIndex].correctAnswer,
                isCorrect: response,
            }]);

            if (response) {
                setScore(score + 1); // Öka poäng om rätt svar
            }

        } catch (error) {
            console.error('Fel vid validering av svar:', error);
            window.alert('Något gick fel vid valideringen.');
            setValidationResult(false);
        }
    };

    const handleNextQuestion = () => {
        setSelectedAnswer(''); // Återställ valt svar
        setValidationResult(null); // Återställ valideringsresultat
        if (currentQuizIndex < quizzes.length - 1) {
            setCurrentQuizIndex(currentQuizIndex + 1);
        } else {
            setMessage('Du har besvarat alla frågor!');
        }
    };

    const handleRetakeQuiz = () => {
        setScore(0);
        setCompletedQuestions(new Set());
        setUserAnswers([]); 
        setCurrentQuizIndex(0);
        setMessage('');
        setValidationResult(null);
    };

    const handleGoBack = () => {
        navigate(-1); // Navigera tillbaka till föreläsningssidan
    };

    const currentQuiz = quizzes[currentQuizIndex];

    return (
        <div className="quiz-containerB">
        <div className="quiz-container">
            <h1>Frågeformulär</h1>
            <button className="back-button" onClick={handleGoBack}>Tillbaka till Delkurser</button>
            {message && <p>{message}</p>}
            
            {currentQuiz && (
                <div className="quiz-card">
                    <h3>{currentQuiz.question}</h3>
                    {currentQuiz.options.map((option, index) => (
                        <div key={index} className="option-container">
                            <input
                                type="radio"
                                value={option}
                                name={`quiz-${currentQuiz.id}`}
                                onChange={() => setSelectedAnswer(option)}
                                checked={selectedAnswer === option}
                                disabled={completedQuestions.has(currentQuizIndex)} // Inaktivera om frågan redan är besvarad
                            />
                            <label>{option}</label>
                        </div>
                    ))}
                    {validationResult === null ? (
                        <button 
                            className="submit-button" 
                            onClick={handleSubmitAnswer}
                            disabled={!selectedAnswer || completedQuestions.has(currentQuizIndex)} // Inaktivera knappen om inget svar är valt eller om frågan redan är besvarad
                        >
                            Skicka Svar
                        </button>
                    ) : (
                        <div>
                            <h3>{validationResult ? 'Rätt svar!' : 'Fel svar!'}</h3>
                            {currentQuizIndex < quizzes.length - 1 ? (
                                <button className="next-button" onClick={handleNextQuestion}>Nästa fråga</button>
                            ) : (
                                <div className="summa">
                                    <h3 style={{ marginBottom: '20px', fontSize: '18px', color: '#624863' }}>Frågor avslutade</h3>
                                    <p style={{ marginTop: '10px', marginBottom: '30px', fontSize: '18px', color: '#333' }}>
                                         Du fick {score} rätt av {quizzes.length}.
                                    </p>
                                    <div className="summary">
                                        <h3>Sammanställning av dina svar:</h3>
                                        <ul>
                                            {userAnswers.map((answer, index) => (
                                                <li key={index}>
                                                    <strong>Fråga:</strong> {answer.question}<br />
                                                    <span>
                                                        <strong>Ditt svar:</strong> {answer.selectedAnswer}<br />
                                                    </span>
                                                    <span>
                                                        <strong>Korrekt svar:</strong> {answer.correctAnswer}<br />
                                                    </span>
                                                    <span className={answer.isCorrect ? 'correct' : 'incorrect'}>
                                                        <strong>Resultat:</strong> {answer.isCorrect ? 'Rätt' : 'Fel'}<br />
                                                    </span>
                                                 </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="button-group">
                                        <button className="next-button" onClick={handleRetakeQuiz}>Gör om frågor</button>
                                        <button className="finish-button" onClick={handleGoBack}>Tillbaka till Delkurser</button>
                                    </div>
                                    
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
        </div>
    );
}

export default Quiz;
