import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/orderdetail.css';

const OrderDetail = () => {
    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState([]);  // Expect an array of order details
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token
                const response = await fetch(`https://gladpedagog-9pc4j.ondigitalocean.app/api/orderdetail/order/${orderId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setOrderDetails(data);
            } catch (error) {
                console.error('Error fetching order details:', error);
                setOrderDetails([]);
            } finally {
                setLoading(false);
            }
        };
    
        fetchOrderDetails();
    }, [orderId]);
    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!orderDetails.length) {
        return <div>Order details not found.</div>;
    }

    return (
        <div className="order-detail-page-container">
            <div className="order-detail-page">
                <h1>Order Details</h1>
                {orderDetails.map((orderDetail, index) => (
                    <div key={index}>
                        <div className="order-item">
                            <span className="order-item-label">Order number:</span>
                            <span className="order-item-value">{orderDetail.orderNumber}</span> {/* Now using OrderNumber */}
                        </div>
                        <div className="order-item">
                            <span className="order-item-label">Kurs ID:</span>
                            <span className="order-item-value">{orderDetail.courseId}</span>
                        </div>
                        <div className="order-item">
                            <span className="order-item-label">Kurs Title:</span>
                            <span className="order-item-value">{orderDetail.courseTitle}</span>
                        </div>
                        <div className="order-item">
                            <span className="order-item-label">Pris:</span>
                            <span className="order-item-value">{orderDetail.price} kr</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderDetail;
