import React, { useState, useEffect } from 'react';
import './adminLecture.css'; 
import { getAllLectures, createLecture, updateLectureById, deleteLectureById } from '../js/api';
import AdminLectureForm from './adminLectureForm';

const AdminLecturePage = () => {
    const [lectures, setLectures] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchLectures = async () => {
            try {
                const response = await getAllLectures();
                setLectures(response.data);
            } catch (error) {
                console.error('Failed to fetch lectures:', error);
            }
        };

        fetchLectures();
    }, []);

    const handleCreateOrUpdateLecture = async (lectureData, id = null) => {
        const formData = new FormData();
        if (lectureData.file) {
            formData.append('file', lectureData.file);
        }
        formData.append('title', lectureData.title);
        formData.append('description', lectureData.description);
        formData.append('courseId', lectureData.courseId);

        const action = id ? 'uppdatera' : 'skapa';
        const confirmMessage = `Är du säker på att du vill ${action} denna föreläsningen?`;
        
        if (window.confirm(confirmMessage)) {
            try {
                if (id) {
                    await updateLectureById(id, formData);
                    window.alert('Föreläsningen har uppdaterats!')
                } else {
                    await createLecture(formData);
                    window.alert('Föreläsningen skapad!')
                }
                const response = await getAllLectures();
                setLectures(response.data);
                setSelectedLecture(null);
            } catch (error) {
                console.error('Failed to create or update lecture:', error);
                window.alert('Fel vid skapandet eller uppdatering av föreläsning.');
            }
        }
    };

    const handleDeleteLecture = async (id) => {
        const confirmDelete = window.confirm('Är du säker på att du vill radera föreläsningen?');

        if (confirmDelete) {
            try {
                await deleteLectureById(id);
                setMessage('Föreläsningen har raderats.');
                const response = await getAllLectures();
                setLectures(response.data);
            } catch (error) {
                console.error('Failed to delete lecture:', error);
                setMessage('Fel vid radering av föreläsning.');
            }
        }
    };

    return (
        <div className="admin-lecture-pageB">
            <div className="admin-lecture-page">
                <h1>Delkurser</h1>

                <div className="admin-create-lecture-form">
                    <h2>{selectedLecture ? 'Uppdatera Delkurs' : 'Skapa Ny Delkurs'}</h2>
                    <AdminLectureForm 
                        initialLecture={selectedLecture} 
                        onSubmit={handleCreateOrUpdateLecture} 
                    />
                </div>

                <div className="admin-lecture-list">
                    {lectures.length > 0 ? (
                        lectures.map((lecture) => (
                            <div key={lecture.id} className="admin-lecture-card">
                                <h2>{lecture.title}</h2>
                                <p>{lecture.description}</p>
                                <div className="admin-button-container">
                                    <button onClick={() => setSelectedLecture(lecture)}>Redigera</button>
                                    <button className="delete" onClick={() => handleDeleteLecture(lecture.id)}>Ta bort</button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Ingen delkurs tillgänglig.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminLecturePage;