/*import React, { useState } from 'react';
import '../css/popup.css';
import { FaTimes } from 'react-icons/fa';

const Popup = ({ isOpen, onClose }) => {

    if (!isOpen) return null;

   
    const handleSubmit = () => {
            // Hantera prenumeration här
            console.log("Prenumeration inskickad");
            onClose();
        
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <span className="popup-close" onClick={onClose}>
                    <FaTimes />
                </span>
                <h2>Missa inte vårt nyhetsbrev!</h2>
                <h3>Ta del av gratis tips och inspiration.</h3>
                <input type="email" 
                       placeholder="Mailadress..*" 
                       className="popup-input" />
                
                <button className="popup-button" onClick={handleSubmit}>Prenumerera!</button>
            </div>
        </div>
    );
};
export default Popup;*/


import React, { useState } from 'react';
import '../css/popup.css';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';  // Importera Axios

const Popup = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');  // Lagra e-postadressen
    const [error, setError] = useState('');  // Hantera felmeddelanden
    const [success, setSuccess] = useState('');  // Visa lyckomeddelande
    const [loading, setLoading] = useState(false);  // Hantera laddningsstatus

    if (!isOpen) return null;

    const handleSubmit = async () => {
        if (!email) {
            setError("Vänligen fyll i en giltig e-postadress.");
            return;
        }

        try {
            // Skicka POST-begäran till Brevo API
            const response = await axios.post(
                'https://api.brevo.com/v3/contacts',  // API-endpoint för att lägga till kontakter
                {
                    email: email,          // E-postadressen användaren skrev in
                    listIds: [2],          // Lista-ID för Brevo-listan där du vill lägga till e-postadresser
                },
                {
                    headers: {
                        'api-key': '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',  // Din API-nyckel
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 201) {
                setSuccess("Tack för din prenumeration!");
                setEmail('');  // Nollställ inputfältet
                setError('');
            }
        } catch (error) {
            setError("Ett fel inträffade. Vänligen försök igen.");
            console.error(error);
        }finally {
            setLoading(false);
            // Stäng popup efter en kort fördröjning för att ge tid för att visa meddelandet
            setTimeout(onClose, 2000);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <span className="popup-close" onClick={onClose}>
                    <FaTimes />
                </span>
                <h2>Missa inte vårt nyhetsbrev!</h2>
                <h3>Ta del av gratis tips och inspiration.</h3>
                <input
                    type="email"
                    placeholder="Mailadress..*"
                    className="popup-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}  // Uppdatera e-postadressen
                />
                
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {success && <p style={{ color: 'black' }}>{success}</p>}

                <button className="popup-button" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Sänder...' : 'Prenumerera!'}
                </button>
            </div>
        </div>
    );
};

export default Popup;
