import React from 'react';
import { useLocation } from 'react-router-dom';
import '../css/coursedetail.css';
import { useNavigate } from 'react-router-dom';

const CourseDetail = () => {
    const navigate = useNavigate();
    const { state: course } = useLocation();

    // Function to format the description
    const formatDescription = (description) => {
        if (!description) return '';

        if (!description.includes('\n')) {
            description = description.replace(/\. /g, '.\n');
        }

        const isHeading = (line) => line.trim().startsWith('#');

        return description.split('\n').map((line, index) => {
            if (isHeading(line)) {
                return (
                    <h3 key={index} className="desc-heading">
                        {line.replace('#', '').trim()}
                    </h3>
                );
            } else if (line.includes('●')) {
                return (
                    <ul key={index}>
                        <li>{line.replace('●', '').trim()}</li>
                    </ul>
                );
            } else {
                return (
                    <p key={index}>
                        {line.trim()}
                    </p>
                );
            }
        });
    };

    // Function to handle adding the course to the cart
    const handleAddToCart = async () => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
    
        if (!token || !userId) {
            alert('User not logged in');
            navigate('/login');  // Redirect to login page if not logged in
            return;
        }
    
        const cartItem = {
            userId: parseInt(userId),
            courseId: course.id,
            courseTitle: course.title,
            coursePrice: course.price,
            quantity: 1
        };
    
        try {
            const response = await fetch('https://gladpedagog-9pc4j.ondigitalocean.app/api/ShoppingCart/add', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cartItem)
            });
    
            if (response.ok) {
                alert(`Course "${course.title}" added to the cart!`);
            } else {
                throw new Error('Failed to add the item to the cart');
            }
        } catch (error) {
            console.error('Error adding item to the cart:', error);
            alert('An error occurred while adding the course to the cart.');
        }
    };
    

    return (
        <div className="parent-container">
            <div className="course-detail-container">
                <h1>{course.title}</h1>
                <div className="course-detail-content">
                    <div className="video-content">
                        <video width="100%" height="400" controls>
                            <source src={course.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="desc-content">
                        <p className="course-description">
                            <strong className='desc-title'>Beskrivning: </strong>
                            {formatDescription(course.description)}
                        </p>
                        <p className="course-price">Pris: {course.price} kr</p>
                        <button className="buy-btn" onClick={handleAddToCart}>Köp</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseDetail;
