
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Startsida from './Component/js/startsida.js'; // Se till att sökvägen är korrekt
import Login from './Component/js/login.js';
import Register from './Component/js/register.js';
import Layout from './Component/layout/layout.js';
import Contact from './Component/js/contact.js';
import About from './Component/js/about.js';
import Course from './Component/js/course.js';
import ShoppingCart from './Component/js/shoppingcart.js';
import Navbar from './Component/layout/navbar.js';
import Lecture from './Component/js/lecture.js';
import MinProfil from './Component/js/minprofil.js'; 
import Order from './Component/js/order.js';
import AdminLecturePage from './Component/admin/adminLecture.js';
import AdminLectureForm from './Component/admin/adminLectureForm.js';
import QuizAdmin from './Component/admin/quizAdmin.js';
import Quiz from './Component/js/quiz.js';
import OrderDetail from './Component/js/orderdetail.js'; // Import OrderDetail
import CourseDetail from './Component/js/coursedetail.js'; // Import CourseDetail
import AdminCourse from './Component/admin/adminCourse.js';
import MyCourse from './Component/js/mycourse.js';
import Checkout from './Component/js/checkout.js';
import AdminUserManagement from './Component/admin/adminUserManagement.js';

const App = () => {
const [isLoggedIn, setIsLoggedIn] = useState(false); 
const [userRole, setUserRole] = useState(null);

useEffect (() => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('userRole');
  setIsLoggedIn(!!token);
  setUserRole(role);
}, []);

  return (
      <Router>
        <Layout isLoggedIn={isLoggedIn} setLoggedIn={setIsLoggedIn} userRole={userRole}>
          <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} userRole={userRole} />  {/* Skickar tillståndet som props */}
          <Routes>
              <Route path="/" element={<Startsida />} />
              
              <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn}/>} />
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole}/>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/course" element={<Course />} />
              <Route path="/shoppingcart" element={<ShoppingCart />} />
              <Route path="/lecture/:id" element={<Lecture />} />
              <Route path="/minprofil" element={<MinProfil />}/>
              <Route path="/order" element={<Order/> } />
              <Route path="/startsida" element={<Startsida />} />
              <Route path="/adminLecture" element={<AdminLecturePage />} />
              <Route path="/adminLectureForm" element={<AdminLectureForm />} />
              <Route path="/quizAdmin" element={<QuizAdmin />}/>
              <Route path="/quiz/:lectureId" element={<Quiz />}/>
              <Route path="/order/:orderId" element={<OrderDetail />} /> {/* Route for OrderDetail */}
              <Route path="/course/:id" element={<CourseDetail />} />
              <Route path="/adminCourse" element={<AdminCourse />}/>
              <Route path="/mycourse" element={<MyCourse />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/adminUserManagement" element={<AdminUserManagement />} />
          </Routes>
         </Layout> 
      </Router>

  );
};



export default App;