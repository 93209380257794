import React, { useEffect, useState } from 'react';
import { getAllUsers, deleteUser } from '../js/api'; // Antag att du har flyttat dina API-anrop till en separat fil
import './adminUserManagement.css'

const AdminUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Funktion för att hämta alla användare
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await getAllUsers(); // Använd API-anropet för att hämta användare
      setUsers(response); // Sätt state med användardata
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch users:', err);
      setError('Kunde inte hämta användarlistan');
      setLoading(false);
    }
  };

  // Funktion för att ta bort en användare baserat på användar-ID
  const handleDeleteUser = async (userId) => {
    try {
      const confirmDelete = window.confirm('Är du säker på att du vill ta bort denna användare?');
      if (!confirmDelete) return;
  
      await deleteUser(userId);
      alert('Användaren har raderats');
      fetchUsers(); // Hämta uppdaterad lista efter borttagning
    } catch (err) {
      console.error('Failed to delete user:', err);
      setError('Kunde inte radera användaren');
    }
  };
  

  useEffect(() => {
    fetchUsers(); // Hämta alla användare vid komponentens inladdning
  }, []);

  return (
    <div className="admin-container"> {/* Lägg till wrapper-klass */}
      <h2> Användarhantering</h2>
      {loading ? (
        <p>Laddar användarlista...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <table className="admin-table"> {/* Använd admin-table-klassen */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Förnamn</th>
              <th>Efternamn</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user.userId}>
                  <td>{user.userId}</td>
                  <td>{user.email}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>
                    <button onClick={() => handleDeleteUser(user.userId)}>Ta bort</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
               <td colSpan="5" style={{ textAlign: 'center', color: '#7e5786' }}>Inga användare hittades</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminUserManagement;
