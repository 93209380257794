import React, { useState, useEffect } from 'react';
import { getAllQuizzes, createQuiz, updateQuiz, deleteQuiz } from '../js/api';
import './quizAdmin.css'; // Importera CSS

function QuizAdmin() {
    const [quizzes, setQuizzes] = useState([]);
    const [newQuiz, setNewQuiz] = useState({ lectureId: '', question : '', options: [], correctAnswer: '' });
    const [editingQuizId, setEditingQuizId] = useState(null);

    useEffect(() => {
        loadQuizzes();
    }, []);

    const loadQuizzes = async () => {
        const response = await getAllQuizzes();
        setQuizzes(response.data);
    }

    const handleCreateQuiz = async () => {
        if(!newQuiz.lectureId || !newQuiz.question || !newQuiz.options || !newQuiz.correctAnswer){
            window.alert('Vänligen fyll i alla fält.')
        }
        try {

        await createQuiz(newQuiz);
        setNewQuiz({ lectureId: '', question : '', options: [], correctAnswer: '' });  // Rensa formuläret efter skapande
        window.alert('Frågan skapad.')
        loadQuizzes();
    }
    catch (error) {
        window.alert('Något gick fel vid skapandet av frågan.')
    }
}

    const handleEditQuiz = (quiz) => {
        setNewQuiz({
            lectureId: quiz.lectureId ? quiz.lectureId.toString() : '',  
            question: quiz.question || '',  
            options: quiz.options || [],  
            correctAnswer: quiz.correctAnswer || ''  
        });
        setEditingQuizId(quiz.id);  
    } 

    const handleUpdateQuiz = async () => {
        if (!newQuiz.lectureId || !newQuiz.question || !newQuiz.options.length || !newQuiz.correctAnswer) {
            window.alert('vänligen fyll i alla fält.')
            return;
        }

        const updatedQuiz = { 
            ...newQuiz, 
            lectureId: parseInt(newQuiz.lectureId, 10),  
            id: editingQuizId  // Använd editingQuizId för att identifiera vilket quiz som ska uppdateras
        };

        try {
            await updateQuiz(editingQuizId, updatedQuiz);
            setNewQuiz({ lectureId: '', question : '', options: [], correctAnswer: '' });  // Rensa formuläret efter uppdatering
            setEditingQuizId(null);  // Återställ redigeringsläget
            window.alert('Frågan uppdaterat.')
            loadQuizzes();
        } catch (error) {
            window.alert('Något gick fel vid uppdateringen.')
        }
    }

    const handleDelete = async (id) => {
        try {
        await deleteQuiz(id);
        window.alert('Frågan raderad.')
        loadQuizzes();
        } catch (error) {
            window.alert('Något gick fel vid raderingen.')
        }
    };

    const handleCancelEdit = () => {
        setNewQuiz({ lectureId: '', question: '', options: [], correctAnswer: '' });
        setEditingQuizId(null);
    };
 
    return (
        <div className="quiz-admin-container">
            <h1>Frågor</h1>
            <div className="quiz-form">
                <input 
                    type="text" 
                    placeholder="Lecture ID" 
                    value={newQuiz.lectureId} 
                    onChange={e => setNewQuiz({ ...newQuiz, lectureId: e.target.value })} 
                />
                <input 
                    type="text" 
                    placeholder="Question" 
                    value={newQuiz.question}
                    onChange={e => setNewQuiz({ ...newQuiz, question: e.target.value })} 
                />
                <input 
                    type="text" 
                    placeholder="Options (comma separated)" 
                    value={newQuiz.options.join(',')} 
                    onChange={e => setNewQuiz({ ...newQuiz, options: e.target.value.split(',') })} 
                />
                <input 
                    type="text" 
                    placeholder="Correct Answer" 
                    value={newQuiz.correctAnswer}
                    onChange={e => setNewQuiz({ ...newQuiz, correctAnswer: e.target.value })} 
                />
                <button onClick={editingQuizId ? handleUpdateQuiz : handleCreateQuiz}>
                    {editingQuizId ? 'Uppdatera Quiz' : 'Skapa Nytt Quiz'}
                </button>
                {editingQuizId && (
                    <button className="cancel-button" onClick={handleCancelEdit}>
                        Avbryt
                    </button>
                )}
            </div>
            <div className="quiz-list">
                {quizzes.map(quiz => (
                    <div key={quiz.id} className="quiz-item">
                        <h3>{quiz.question}</h3>
                        <p><strong>Föreläsnings ID:</strong> {quiz.lectureId}</p>
                        <p><strong>Svars alternativ:</strong> {quiz.options.join(', ')}</p>
                        <p><strong>Rätt svar:</strong> {quiz.correctAnswer}</p>
                        <button onClick={() => handleEditQuiz(quiz)}>Uppdatera</button>
                        <button onClick={() => handleDelete(quiz.id)}>Radera</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default QuizAdmin;
