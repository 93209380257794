

import axios from 'axios';

// Skapa en instans av Axios med bas-URL
const api = axios.create({
    baseURL: 'https://gladpedagog-9pc4j.ondigitalocean.app/',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}` // Använd token från localStorage
    }
});

export const getLecturesByCourseId = async (courseId) => {
    try {
        const token = localStorage.getItem('token');  // Retrieve JWT token from localStorage
        const response = await axios.get(`https://gladpedagog-9pc4j.ondigitalocean.app/api/lecture/byCourse/${courseId}`, {
            headers: {
                Authorization: `Bearer ${token}`  // Add the JWT token in the Authorization header
            }
        });
        return response;
    } catch (error) {
        console.error("Error fetching lectures by course ID", error);
        throw error;
    }
};


// Registrera användare
export const registerUser = async (firstName, lastName, email, password) => {
    return api.post('/api/Account/register', { firstName, lastName, email, password });
};


export const loginUser = async (email, password) => {
    console.log('Sending login request');
    const response = await api.post('/api/Account/login', { email, password });
    console.log('Login response:', response);
    return response;
};

// Uppdatera användarprofil
export const updateUserProfile = async (userId, firstName, lastName, street, city, postalCode, country, password) => {
    return await api.put('/user/user/Update-Profile', { 
        userId,
        firstName, 
        lastName, 
        street, 
        city, 
        postalCode, 
        country, 
        passwordHash: password // Om lösenordet ska uppdateras
    });
};


// Hämta alla användare (endast för admin)
export const getAllUsers = async () => {
    const response = await api.get('/user/user');
    return response.data;
};

export const getUserById = async (id) => {
    const token = localStorage.getItem('token'); // Hämta token från localStorage
    if (!id) {
        throw new Error('User ID is missing');
    }
    try {
        const response = await api.get(`/user/user/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user profile:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Ta bort en användare baserat på användar-ID (endast för admin)
export const deleteUser = async (userId) => {
    try {
      const url = `https://gladpedagog-9pc4j.ondigitalocean.app/user/user/${userId}`;  // Ändra detta om din backend route är annorlunda
      console.log('Deleting user with URL:', url); // Lägg till logg för att verifiera URL
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      console.error('Failed to delete user:', error.response ? error.response.data : error.message);
      console.error('Status code:', error.response ? error.response.status : 'No response from server');
      throw error;
    }
  };
  
  

// DETTA ÄR FÖR FÖRELÄSNING

// Hämta alla föreläsningar
export const getAllLectures = async () => {
    return await api.get('/api/lecture/getAll');
};


export const createLecture = async (formData) => {
    return api.post('/api/Lecture/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const updateLectureById = async (id, formData) => {
    return api.put(`/api/Lecture/update/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// Ta bort föreläsning
export const deleteLectureById = async (id) => {
    return await api.delete(`/api/Lecture/delete/${id}`);
};

// Hämta föreläsning efter ID
export const getLectureById = async (id) => {
    return api.get(`/api/Lecture/${id}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Om du använder JWT-token
        }
    });
};



//DETTA ÄR FÖR QUIZ

// hämta alla frågor
export const getAllQuizzes = async () => {
    return await api.get('/api/quiz');
}

// hämta baserat på id
export const getQuizById = async (id) => {
    return await api.get(`/api/quiz/${id}`);
}

// skapa quiz
export const createQuiz = async (quiz) => {
    return await api.post('/api/quiz', quiz);
}

// uppdatera quiz
export const updateQuiz = async (id, quiz) => {
    try {
        const response = await api.put(`/api/quiz/${id}`, quiz);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Error in updateQuiz:', error.response.data.errors);
        } else {
            console.error('Error in updateQuiz:', error.message);
        }
        throw error;
    }
}

// ta bort quiz
export const deleteQuiz = async (id) => {
    return await api.delete(`/api/quiz/${id}`);
}

export const validateAnswer = async (id, answer) => {
    /*
    return await api.post(`/api/quiz/${id}/validate`, { userAnswer: answer });*/
   /* try {
        // Skicka sträng direkt utan JSON-objekt
        const response = await api.post(`/api/quiz/${id}/validate`, answer);
        return response.data;
    } catch (error) {
        console.error('Error validating answer:', error);
        throw error;
    }*/
        try {
            // Skicka svaret som en JSON-sträng
            const response = await api.post(`/api/quiz/${id}/validate`, JSON.stringify(answer), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data; // Anta att detta är en boolean
        } catch (error) {
            console.error('Error validating answer:', error);
            throw error;
        }
};






export const getAllCourses = () => api.get('/course/GetAllCourses');
export const createCourse = (formData) => api.post('/course/CreateCourseWithVideo', formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const updateCourse = (id, formData) => api.put(`/course/${id}`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const deleteCourse = (id) => api.delete(`/course/${id}`);



//Fakturor
export const fetchAllInvoices = async () => {
    try {
        const response = await api.get('/api/invoice/all');
        return response.data;
    } catch (error) {
        console.error('Error fetching invoices:', error);
        throw error;
    }
};

export const deleteInvoice = async (id) => {
    try{
        await api.delete(`/api/invoice/${id}`);
    } catch (error) {
        console.error('Error deleting invoice:', error);
        throw error;
    }
}



// shoppingcart

export const getCartItems = async (userId) => {
    const token = localStorage.getItem('token'); // Hämta token från localStorage
    try {
        const response = await api.get(`/api/ShoppingCart/items/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Skicka JWT-token i Authorization-headern
            }
        });
        return response.data; // Returnera datan från API-anropet
    } catch (error) {
        console.error("Error fetching cart items", error);
        throw error; // Kasta felet så att det hanteras i komponenten
    }
};

export const removeCartItem = async (cartItemId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`https://gladpedagog-9pc4j.ondigitalocean.app/api/ShoppingCart/remove/${cartItemId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to remove item');
        }
        return response;
    } catch (error) {
        console.error("Error removing cart item", error);
        throw error;
    }
};