import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logga from '../img/logga.png';
import '../css/startsida.css';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InstagramIcon from '@mui/icons-material/Instagram';
import '../css/footer.css';
import axios from 'axios';

const Footer = () => {
    const [name, setName] = useState('');  // Lagra namn
    const [email, setEmail] = useState('');  // Lagra e-post
    const [error, setError] = useState('');  // Hantera felmeddelanden
    const [success, setSuccess] = useState('');  // Visa lyckomeddelande
    const [loading, setLoading] = useState(false);  // Hantera laddningsstatus

    const handleSubmit = async () => {
        if (!name || !email) {
            setError("Vänligen fyll i både namn och e-postadress.");
            setTimeout(() => setError(''), 5000);
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(
                'https://api.brevo.com/v3/contacts',  // API-endpoint för att lägga till kontakter
                {
                    email: email,
                    name: name,          // Lägg till namn
                    listIds: [2],        // Lista-ID för Brevo-listan där du vill lägga till e-postadresser
                },
                {
                    headers: {
                        'api-key': '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',  // Använd miljövariabel
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 201) {
                setSuccess("Tack för din prenumeration!");
                setName('');  // Nollställ namninputfältet
                setEmail('');  // Nollställ e-postinputfältet
                setTimeout(() => setSuccess(''), 5000);
            }
        } catch (error) {
            setError("Ett fel inträffade. Vänligen försök igen.");
            console.error(error);
            setTimeout(() => setError(''), 5000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
        <div className="rectangle-355">
            <div className="subscription-section">
                <p className="subscribe-text">Prenumerera på Gladpedagogs nyhetsbrev för mer inspiration!</p>
                <div className="message-container">
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                </div>
                <div className="row-section">
                    <input
                        type="text"
                        placeholder="Namn.."
                        className="input-field"
                        value={name}
                        onChange={(e) => setName(e.target.value)}  // Uppdatera namn
                    />
                    <input
                        type="email"
                        placeholder="Mailadress.."
                        className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  // Uppdatera e-post
                    />
                    <button
                        className="subscribe-button"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Sänder...' : 'Prenumerera'}
                    </button>
                </div>
            </div>
        </div>

            {/* Footer */}
            <div className="rectangle-377">
    <p className="footer-text">
    <a href="https://www.instagram.com/gladpedagog/" target="_blank" rel="noopener noreferrer" className="insta-link">
            <InstagramIcon className="insta-icon" />
        </a>
        <span>Följ Glad Pedagog på Instagram! Glad Pedagog 2024</span>
    </p>
</div>

        
        </div>

    
);
};

export default Footer;